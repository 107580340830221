<template>
  <div class="navbar">
      <el-row class="navbar_div" style="height: 50px;line-height: 50px;text-align: center;">
          <el-col :span="2" v-for="itme in navBar">
              <el-link type="primary" :class="nav_type==itme.id?'xuanzhong':''" style="" @click="modebase(itme.id,itme.node_id,itme.sequence)">{{itme.name}}</el-link>
          </el-col>
          <el-col :span="2">
              <el-link type="primary" style="":class="nav_type==3?'xuanzhong':''" class='actives' @click="myproject">我的秀秀</el-link>
          </el-col>
		  <!-- <el-col :span="2">
		      <el-link type="primary" style="":class="nav_type==4?'xuanzhong':''" class='actives' @click="PaidServices">收费资讯</el-link>
		  </el-col> -->
      </el-row>
  </div>
</template>
<script>
    import { requestCatalog } from "../api/request";

    export default {
    data () {
        return {
            navBar:[],
            radio: '1',
            level_0:"999999",
            parentnodeid:"0",
            sequence:"1",
            level_1:"1000000",
            parentnodeid1:"0",
            sequence1:"2",
            nav_type:0
        };
    },
  methods: {
    myproject(){
        let token_nav= this.Account.GetToken();
        if(token_nav==null){
            this.$parent.logo_open();
            return
        }else {
			this.$router.push({ path: "MyShow", query: {level_0:3} });
        }
    },
    modebase(level,parentnodeid,sequence){
        this.$router.push({path:'/ModeBase',query: {level_0:level,parentnodeid:parentnodeid,sequence:sequence}})
    }
  },
    watch: {
        $route( to , from ){
            if(to.query.level_0!=-1 ){
                this.nav_type=to.query.level_0
            }
        },
    },
    mounted(){
        requestCatalog(0).then(res => {
            this.navBar=res.ret;
        })
    }
};
</script>
<style scoped>
.navbar{
  width: 1350px;
  margin: 0 auto;
}
.navbar_div>div{
    text-align: center;
}
.navbar_div>div>a{
    display: inline-block;
    border-left: 1px solid #e6e6e6;
    width: 100%;
    height: 30px;
    line-height: 30px;
    color:#828282;
	font-weight: bold;
}
.navbar_div>div:last-child>a{
    border-right: 1px solid #e6e6e6;
}

.navbar b{
    padding: 10px 20px 0;
    font-size: 14px;
    align-items: center;
    color: #3f3f3f;
    cursor: pointer;
}
.navbar span {
  color:#dde2e6;
}
.actives:active{
	color: #000000;
}
/* .xuanzhong{
    color: #007bff;
    border-bottom: 1px solid #007bff !important;
} */
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import md5 from 'js-md5';

Vue.prototype.$md5 = md5;
Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import common from '@/assets/js/commonfun.js'
import tree_catalog from '@/assets/js/tree.js'

import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

Vue.prototype.common=common;
Vue.prototype.tree_catalog=tree_catalog;

router.beforeEach((to,from,next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
});

Vue.component(CollapseTransition.name, CollapseTransition)

//import "./assets/css/reset.css"
Vue.use(ElementUI)

import MyShow from "./assets/js/data/MyShow";
Vue.prototype.MyShow=MyShow;
import DataManager from "./assets/js/data/DataManager";
Vue.prototype.DataManager=DataManager;
import Utility from "./assets/js/utility/Utility";
Vue.prototype.Utility=Utility;
import Account from "./assets/js/data/Account";
Vue.prototype.Account=Account;
import DefineType from "./assets/js/define/DefineType";
Vue.prototype.DefineType=DefineType;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

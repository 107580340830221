/**
 */

export default {
    isContains: function(str, substr) {
        return str.indexOf(substr) >= 0;
    },
    //是否是汉字
    isChinese: function(val) {
        var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
        if (reg.test(val)) {
            return true;
        }
        return false;
    },
};
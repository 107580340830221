export default {
    //
    Directory_MyProject:1,
    Directory_MyCollect:2,
    Directory_MyShare:3,

    condition_state_all:3,
    condition_state_up:1,
    condition_state_down:2,

    condition_type_all:3,
    condition_type_model:0,
    condition_type_animation:2,
    
};

export default {
    jsArrayToJsonString : function(arr)
    {
        return JSON.stringify( arr );
    },
    jsonObjectToString : function(jsonObject)
    {
        return JSON.stringify(jsonObject);
    },
    jsonStringToObject : function(jsonStr)
    {
        return JSON.parse( jsonStr );
    },
};
export default {
	//
	MyShowVue:null,
	//一级菜单
	level_one_list:[],
	init_level_one_list:function(data)
	{
		this.level_one_list = data;
		for (let index = 0; index < this.level_one_list.length; index++) {
			const element = this.level_one_list[index];
		}
	},
	//二级菜单[{id:1,data:[]},]
	level_two_list:[],
	get_level_two_item_by_nodeId:function(node_id)
	{
		for (let index = 0; index < this.level_two_list.length; index++) {
			for (let index2 = 0; index2 < this.level_two_list[index].data.length; index2++) {
				const element = this.level_two_list[index].data[index2];
				if (element.node_id == node_id)
				{
					return element;
				}
			}
		}
		return null;
	},
	get_level_two_list:function(id)
	{
		for (let index = 0; index < this.level_two_list.length; index++) {
			const element = this.level_two_list[index];
			if (element.id == id)
			{
				return element.data;
			}
		}
		return null;
	},
	set_level_two_list:function(id,data)
	{
		for (let index = 0; index < this.level_two_list.length; index++) {
			const element = this.level_two_list[index];
			if (this.level_two_list[index].id == id)
			{
				this.level_two_list[index].data = data;
				return;
			}
		}
		this.level_two_list.push({id:id,data:data}); 
	},
	//资源[{id:1,data:[]},]
	resource_list:[],
	get_resource_list:function(id)
	{
		for (let index = 0; index < this.resource_list.length; index++) {
			const element = this.resource_list[index];
			if (element.id == id)
			{
				return element.data;
			}
		}
		return null;
	},
	set_resource_list:function(id,data)
	{
		for (let index = 0; index < this.resource_list.length; index++) {
			const element = this.resource_list[index];
			if (this.resource_list[index].id == id)
			{
				this.resource_list[index].data = data;
				return;
			}
		}
		this.resource_list.push({id:id,data:data});
	},
	add_resource_list:function(id,data)
	{
		for (let index = 0; index < this.resource_list.length; index++) {
			const element = this.resource_list[index];
			if (this.resource_list[index].id == id)
			{
				this.resource_list[index].data = data;
				return;
			}
		}
		this.resource_list.push({id:id,data:data});
	},

	
	all_resource_list:[],
	get_all_resource_list:function(id)
	{
		if(this.all_resource_list.length==0){
			return null
		}else{
			this.all_resource_list
		}
	},
	set_all_resource_list:function(item_data)
	{
		this.all_resource_list=item_data;
	},
	add_all_resource_list:function(id,data)
	{
		for (let index = 0; index < this.all_resource_list.length; index++) {
			const element = this.all_resource_list[index];
			if (this.all_resource_list[index].id == id)
			{
				this.all_resource_list[index].data = data;
				return;
			}
		}
		this.all_resource_list.push({id:id,data:data});
	},
	
	HomeCatalog:[],
	SetHomeCatalog:function(data){
		console.log(data)
		this.HomeCatalog=data;
		localStorage.setItem('HomeCatalog', JSON.stringify({ data: data, time: Date.now() + 1000 * 60 * 3600 }) );
	},
	GetHomeCatalog:function(){
		console.log(Date.now(),new Date().getTime())
		if(this.HomeCatalog.length==0){
			var res = JSON.parse(localStorage.getItem('HomeCatalog'));
			console.log(JSON.parse(localStorage.getItem('HomeCatalog')))
			if(res){
				if (Date.now()-res.time>1000){
					return null
				}else{
					return res.data
				}
			}else{
				return null
			}
			// console.log(res.data, res.time)
			// return JSON.parse(localStorage.getItem('HomeCatalog')).data;
		}else{
			return this.HomeCatalog;
		}
	},
    //二级列表
    Child_list: [],
    //一级列表
    projectList: [],
	//
	level2_data_list:[],
	level2_Child_list:[],
    //level2_listData
	level2_listData:[],
	listData:[],
	//判断是否重复
	repeatONE:0,
	repeatTOW:0,
	activeNames:0,
	//全部记录
	All_records:[],
	//全部记录长度
	All_records_length:null,
	//审核通过
	Approved:[],
	//审核通过长度
	Approved_length:null,
	//待审核
	To_be_reviewed:[],
	//待审核长度
	To_be_reviewed_length:null,
	//驳回
	Rejection:[],
	//驳回数据长度
	Rejection_length:null,
	//已下架
	Off_the_shelf:[],
	//已下架长度
	Off_the_shelf_length:null,
	//点击一级列表
	parentdata:null,
	NotonSale:[],
	NotonSale_length:null,
	
    parseProtocal: function(data) 
    {
        this.projectList = data;
        this.level1_data_list = data;
	},
	
	changeDirectory:function(item)
	{

	}
};

export default {
    //退出
    LogOut:function()
    {
		localStorage.removeItem("token");
		localStorage.removeItem("session");
		localStorage.removeItem("storage");
    },
    GetToken:function(){
		return localStorage.getItem('token')
	},
	GetStorage:function(){
		return JSON.parse(localStorage.getItem('storage'))
	},
	GetSession:function(){
		return JSON.parse(localStorage.getItem('session'))
	},
    parseProtocal: function(data)
    {
        this.projectList = data;
        this.level1_data_list = data;
    },
};

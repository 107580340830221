<template>
  <div id="app">

     <headerTop ref='headerTop' id='header'></headerTop><!-- 头部公共信息 -->
    <!-- <navBar ref='navBar'></navBar> -->
    <router-view />

    <login ref="login" v-show="showlogin" @closeDialog="closeDialog"></login>
	<!-- <Rightfloatingframe ref='Rightfloatingframe'></Rightfloatingframe> -->
  </div>
</template>
<script>
import headerTop from '@/components/headerTop.vue'
import navBar from '@/components/navBar.vue'
import login from "@/views/Login.vue";
// import Rightfloatingframe from "@/components/Rightfloatingframe.vue";
import App from './App.vue'

  export default{
    name:'',
    data(){
      return{
          showlogin:false,
      }
    },
    components:{
      headerTop,
      navBar,
      login,
	  // Rightfloatingframe
    },
      methods: {
        logo_open:function () {
            this.showlogin=true;
        },
          closeDialog(data) {
              this.showlogin = data;
          },
      },
  }
</script>
<style>

</style>

export default {
    //图面统一前缀
    retun_img(e) {
        if (e != undefined) {
            if (e.split(".")[e.split(".").length - 1] == "bfm2" || e.split(".")[e.split(".").length - 1] == "bfm" || e.split(".")[e.split(".").length - 1] == "mp4" || e.split(".")[e.split(".").length - 1] == "zip") {
                return "暂无预览"
            } else {
                return "https://resourcelib.oss-cn-beijing.aliyuncs.com/upload/ico/" + e;
            }
        }

    },
    //oss的前缀
    retun_oss_shuiyin_img(e) {
        return "https://res.bimshows.cn/res/getwatermarkimage/" + e;
    },
    //oss的前缀
    retun_ossimg(e) {
        return "https://resourcelib.oss-cn-beijing.aliyuncs.com/upload/" + e;
    },
    //oss的前缀
    retun_touxiangimg(e) {
        if (e != undefined) {
            return "https://resourcelib.oss-cn-beijing.aliyuncs.com/" + e;
        } else {
            return "/img/headpath.e2c620ca.png";
        }

    },
    retun_nodeid(pnode, level, sequence) {
        let obj = {
            "Max": parseInt(pnode) + Math.pow(2, 56 - 7 * level) * (parseInt(sequence) + 1),
            "Min": parseInt(pnode) + Math.pow(2, 56 - 7 * level) * parseInt(sequence) - 1
        }
        return obj;
    },
    retun_nodeid_50digit(pnode, level, sequence) {
        let obj = {
            "Max": parseInt(pnode) + Math.pow(2, 50 - 7 * level) * (parseInt(sequence) + 1),
            "Min": parseInt(pnode) + Math.pow(2, 50 - 7 * level) * parseInt(sequence) - 1
        }
        return obj;
    },
    retun_getnodeid(pnode, level, sequence) {
        let obj = parseInt(pnode) + Math.pow(2, 50 - 7 * level) * parseInt(sequence)
        return obj;
    },
    //时间戳转为时间
    retun_formatDate11(time) {
        console.log(time)
        time = time * 1000
        let date = new Date(time)
        return this.formatDate(date, 'yyyy-MM-dd hh:mm')
    },
    //秒转为时间格式
    formatSeconds(value) {
        let result = parseInt(value)
        let h = Math.floor(result / 3600) < 10 ? Math.floor(result / 3600) : Math.floor(result / 3600);
        let m = Math.floor((result / 60 % 60)) < 10 ? Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
        let s = Math.floor((result % 60)) < 10 ? Math.floor((result % 60)) : Math.floor((result % 60));
        let res = '';
        if (h != '0') res += `${h}h`;
        if (m != '0') res += `${m}m`;
        res += `${s}s`;
        return res;
    },
    //下标转换ABCD
    retun_abc(nub) {
        if (nub == 0) {
            return "A"
        } else if (nub == 1) {
            return "B"
        } else if (nub == 2) {
            return "C"
        } else if (nub == 3) {
            return "D"
        } else if (nub == 4) {
            return "E"
        } else if (nub == 5) {
            return "F"
        } else if (nub == 6) {
            return "G"
        } else if (nub == 7) {
            return "H"
        }
    },
    //13位时间戳转换时间
    retun_formatDate13(time) {
        let date = new Date(time);
        return this.formatDate(date, 'yyyy-MM-dd')
    },
    formatDate(date, fmt) {

        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        let o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'h+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds()
        }
        for (let k in o) {
            if (new RegExp(`(${k})`).test(fmt)) {
                let str = o[k] + ''
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str))
            }
        }
        return fmt
    },
    padLeftZero(str) {
        return ('00' + str).substr(str.length)
    },
    //获取当前url的参数
    GetRequest(url) {
        if (typeof (url) == "undefined") url = location.href;
        var theRequest = new Object();
        if (url.indexOf("?") != -1) {
            var str = url.substr((url.indexOf("?") + 1)), strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
            }
        }
        return theRequest;
    },
    //计算金币
    coin_calculation(calculation, number) {
        let storage_nav = JSON.parse(localStorage.getItem("storage"));
        if (calculation == 1) {
            storage_nav.goldcoin = storage_nav.goldcoin - number;
        } else {
            storage_nav.goldcoin = storage_nav.goldcoin + number;
        }
        localStorage.setItem('storage', JSON.stringify(storage_nav))

        return true
    },
    onlyNumber(value) {
        if (value != '' && value != undefined && value != NaN) {
            let val = value;
            let newValue = val.toString().replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
            return parseInt(newValue == '' ? 0 : newValue);
        } else return 0
    },
    modalnormaldownload(fileName, url) {
        var is360 = this._mime("type", "application/vnd.chromium.remoting-viewer");
        if (is360) {
            window.location.href = url;
        }
        else {
            this.normaldownload(fileName, url);
        }
    },
    normaldownload(fileName, url) {
        var eleLink = document.createElement('a');
        eleLink.download = fileName;
        eleLink.style.display = 'none';
        eleLink.href = url;
        document.body.appendChild(eleLink);
        eleLink.click();
        document.body.removeChild(eleLink);
    },
    _mime(option, value) {
        var mimeTypes = navigator.mimeTypes;
        for (var mt in mimeTypes) {
            if (mimeTypes[mt][option] == value) {
                return true;
            }
        }
        return false;
    },





    download(data, strFileName, strMimeType) {
        console.log(strMimeType)
        let _this = this;
        var self = window, // this script is only for browsers anyway...
            u = "application/octet-stream", // this default mime also triggers iframe downloads
            m = strMimeType || u,
            x = data,
            D = document,
            a = D.createElement("a"),
            z = function (a) {
                return String(a);
            },


            B = self.Blob || self.MozBlob || self.WebKitBlob || z,
            BB = self.MSBlobBuilder || self.WebKitBlobBuilder || self.BlobBuilder,
            fn = strFileName || "download",
            blob,
            b,
            ua,
            fr;

        //if(typeof B.bind === 'function' ){ B=B.bind(self); }

        if (String(this) === "true") { //reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
            x = [x, m];
            m = x[0];
            x = x[1];
        }



        //go ahead and download dataURLs right away
        if (String(x).match(/^data\:[\w+\-]+\/[\w+\-]+[,;]/)) {
            return navigator.msSaveBlob ? // IE10 can't do a[download], only Blobs:
                navigator.msSaveBlob(this.d2b(x), fn) :
                saver(x); // everyone else can save dataURLs un-processed
        } //end if dataURL passed?

        try {

            blob = x instanceof B ?
                x :
                new B([x], {
                    type: m
                });
        } catch (y) {
            if (BB) {
                b = new BB();
                b.append([x]);
                blob = b.getBlob(m); // the blob
            }

        }



        function d2b(u) {
            var p = u.split(/[:;,]/),
                t = p[1],
                dec = p[2] == "base64" ? atob : decodeURIComponent,
                bin = dec(p.pop()),
                mx = bin.length,
                i = 0,
                uia = new Uint8Array(mx);

            for (i; i < mx; ++i) uia[i] = bin.charCodeAt(i);

            return new B([uia], {
                type: t
            });
        }

        function saver(url, winMode) {


            if ('download' in a) { //html5 A[download]
                a.href = url;
                a.setAttribute("download", fn);
                a.innerHTML = "downloading...";
                D.body.appendChild(a);
                setTimeout(function () {
                    a.click();
                    D.body.removeChild(a);
                    if (winMode === true) {
                        setTimeout(function () {
                            self.URL.revokeObjectURL(a.href);
                        }, 250);
                    }
                }, 66);
                return true;
            }

            //do iframe dataURL download (old ch+FF):
            var f = D.createElement("iframe");
            D.body.appendChild(f);
            if (!winMode) { // force a mime that will download:
                url = "data:" + url.replace(/^data:([\w\/\-\+]+)/, u);
            }


            f.src = url;
            setTimeout(function () {
                D.body.removeChild(f);
            }, 333);

        } //end saver


        if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
            return navigator.msSaveBlob(blob, fn);
        }

        if (self.URL) { // simple fast and modern way using Blob and URL:
            saver(self.URL.createObjectURL(blob), true);
        } else {
            // handle non-Blob()+non-URL browsers:
            if (typeof blob === "string" || blob.constructor === z) {
                try {
                    return _this.saver("data:" + m + ";base64," + self.btoa(blob));
                } catch (y) {
                    return _this.saver("data:" + m + "," + encodeURIComponent(blob));
                }
            }

            // Blob but not URL:
            fr = new FileReader();
            fr.onload = function (e) {
                saver(this.result);
            };
            fr.readAsDataURL(blob);
        }
        return true;
    },
    /* end download() */
    downloadfile(url, strFileName, strMimeType) {

        var xmlHttp = null;
        let _this = this;
        if (window.ActiveXObject) {
            // IE6, IE5 浏览器执行代码
            xmlHttp = new ActiveXObject("Microsoft.XMLHTTP");

        } else if (window.XMLHttpRequest) {
            // IE7+, Firefox, Chrome, Opera, Safari 浏览器执行代码
            xmlHttp = new XMLHttpRequest();

        }
        //2.如果实例化成功，就调用open（）方法：
        if (xmlHttp != null) {

            xmlHttp.open("get", url, true);
            xmlHttp.responseType = 'blob'; //关键
            xmlHttp.send();
            xmlHttp.onreadystatechange = doResult; //设置回调函数
        }

        function doResult() {

            if (xmlHttp.readyState == 4) { //4表示执行完成
                console.log('----------------------0---------------------', xmlHttp.status);

                if (xmlHttp.status == 200) { //200表示执行成功

                    _this.download(xmlHttp.response, strFileName, strMimeType);
                }
            }
        }
    }









}

const data_tree=[];

export default {
    data_tree,
    is_tree_data:function(a,b){
        if(a==undefined){ return false;}
        let isshow = 0;//1存在    0不存在
        for(let i=0;i<data_tree.length;i++){
            if(data_tree[i].hasOwnProperty(a)){
                isshow=1;
            }
        }
        if(isshow==0){
            return false;
        }else {
            return data_tree;
        }
    },
    tree_data:function(a,b){
            let tree_data=Object();
            let key = a;
            let value = b;
            tree_data[key] = value;
            data_tree.push(tree_data)
            return data_tree;
    },
}

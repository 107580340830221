<template>
    <div class="ComTopSearch">
        <el-col class='Search' style="width: 100%; margin-top: 12px;">
            <el-col style="width:15%;">
                <el-select v-model="select_value" placeholder="请选择"class="option_inp">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-col>
			<!-- <el-button size="mini" type="primary" plain ><p @click="Searchbtn(2)">模型库</p></el-button>
			<el-button size="mini" type="primary" plain ><p @click="Searchbtn(3)">动画库</p></el-button>
			<el-button size="mini" type="primary" plain ><p @click="Searchbtn(4)">脚本库</p></el-button> -->
			<!-- <el-button size="mini" type="primary" plain>动画库</el-button>
			<el-button size="mini" type="primary" plain>脚本库</el-button> -->
            <el-col style="width: 85%;">
                <el-input v-model="input_content" placeholder="请输入关键字"style="border-radius:0px; display: block; border-radius:0px;"  @keyup.enter.native="btn_click_search()"></el-input>
            </el-col>
        </el-col>
    </div>
</template>

<script>
/*
    使用组件：
        <ComTopSearch ref="ComTopSearch"></ComTopSearch>
        let data =  [
                {
                    value: "1",
                    label: "BIM模型库",
                },
                {
                    value: "2",
                    label: "BIM动画库",
                },
                {
                    value: "3",
                    label: "我的秀秀",
                },
            ];

        test_callback(data,select_index)
        {
          console.log(data);
          console.log(select_index);
        }

        this.$refs.ComTopSearch.init(data,this.test_callback);
*/
export default {
    data() {
        return {
            input_content:"",
            select_value:null,
            options: [],
            func_search_callback: null,
        };
    },
    components: {
    },

    methods: {
        //设置默认选中
        default(){
            //如果没有这句代码，select中初始化会是空白的，默认选中就无法实现
            this.select_value = this.options[0].value;
        },
        //初始化数据
        init(options, func_search_callback) {
            console.log(options)
            if (options != null)
            {
                this.options = options;
            }
            if (func_search_callback != null)
            {
                this.func_search_callback = func_search_callback;
            }
            this.default()
        },
		Searchbtn(value){
			this.select_value=value;
		},
        //点击搜索按钮
        btn_click_search() {
			
            let  type=this.select_value;//type
            let name=this.input_content;//name
            if(name==""){
                this.$message({
                    message: "关键字不能为空",
                    type: "warning",
                });
                return
            }
            this.$router.push({ path: "/Searchlist", query: { type:type,name:name } });

        },
    },
    watch: {
        // to , from 分别表示从哪跳转到哪，都是一个对象
        // to.path  ( 表示的是要跳转到的路由的地址 eg: /home );
        $route( to , from ){
            if(this.$route.query.type!=undefined && this.$route.query.name!=undefined){
                for(let i=0;i<this.options.length;i++){
                    if( this.options[i].value==this.$route.query.type){
                        this.select_value = this.options[i].value;
                    }
                }
                this.input_content=this.$route.query.name;
            }else {
                this.input_content="";
                this.select_value = this.options[0].value;
            }
        },
    },
    mounted() {
        if(this.$route.query.type!=undefined && this.$route.query.name!=undefined){
            for(let i=0;i<this.options.length;i++){
                if( this.options[i].value==this.$route.query.type){
                    this.select_value = this.options[i].value;
                }
            }
            this.input_content=this.$route.query.name;
        }
    },
};
</script>

<style scoped>
.transition-box {
    margin-bottom: 10px;
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #409eff;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
}
a {
    text-decoration: none;
}
.Search{
	transition: 1s;
}
.login span {
    font-size: 14px;
    color: #999;
    cursor: pointer;
}


/* 小窗体信息 */
.img-message {
    width: 250px;
    height: 340px;
    background-color: #ffffff;
    border: 1px solid #bbbbbb;
    border-radius: 8px;
    transform: translate(1010px, 10px);
    padding: 1px;
    font-size: 14px;
    z-index: 80000;
    position: absolute;
}
.dredge-vip1,
.dredge-vip2,
.internal-storage1,
.internal-storage2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 6px;
}
.dredge-vip1,
.dredge-vip2,
.internal-storage1,
.internal-storage2 {
    height: 42px;
    width: 240px;
    /* background-color: red; */
}
.dredge-vip1,
.dredge-vip2,
.internal-storage1 span b {
    color: #007bff;
}
.dredge-vip1 span b,
.dredge-vip2 span b,
.internal-storage1 span b {
    cursor: pointer;
}
.dredge-vip {
    border-bottom: 1px solid #bbbbbb;
}
.dredge-vip-cc {
    color: black;
}
.dredge-vip-cc a {
    font-size: 12px;
    color: #999;
}
.modifieddata {
    width: 250px;
    height: 137px;
    background-color: #1488ff;
    font-size: 14px;
    color: #fff;
    border-radius: 4px;
}
.modifieddata-one {
    height: 60px;
}
.modifieddata span {
    display: block;
    margin-top: 46px;
    text-align: center;
}
.modifieddata span b {
    margin-left: 9px;
    cursor: pointer;
}
.modifieddata-one img {
    height: 71px;
    width: 71px;
    margin-left: 98px;
    margin-top: 20px;
    /* display: block; */
}
.dredge-vip {
    width: 250px;
    height: 86px;
}
.internal-storage {
    width: 250px;
    height: 86px;
}
.img-message-bottom {
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #f4f4f4;
}
.img-message-bottom span {
    font-size: 14px;
    color: #007bff;
    margin-left: 30px;
    height: 15px;
    cursor: pointer;
}
>>>.el-select .el-input__inner{
	padding: 0;
}
>>>.el-input--suffix .el-input__inner{
	padding: 0;
	
}
>>>.el-input__inner{
	backdrop-filter:blur(8px) !important;
	font-size: 12px;
	background:hsla(0,0%,100%,.1); 
	/* text-indent: 20px; */
	color: #000000;
	border-radius: inherit;
}
>>>.option_inp input{
	/* border: none; */
	border-right: none;
	text-indent: 10px;
}
>>>.el-select-dropdown__item.selected{
	color: #cb5031 !important;
}
</style>

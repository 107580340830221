<template>
<!-- <div class="login-dialog"> -->
    <div class="draw-Box">
        <div class="model-dialog model-lg " style="">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">登录</h4>
                    <button type="button" @click="closeDialog" class="close"><img src="../assets/img/关闭.png" alt=""></button>
                </div>
                <div class="modal-body">
                    <div class="row justify-content-center align-content-center login-center">
						<img class="mt-4 pt-1" width="185" style="display: block; margin:50px auto;" src="../assets/img/logo.png" alt="">
                        <div class="text-center">

                            <form class="mt-5" action="" method="post">
                                <div class="input-group mb-3 ">
                                    <input id="username" type="text"  v-model="user"  class="form-control" placeholder="手机号" name="phone">
                                </div>
                                <div class="input-group mb-3 ">
                                    <input type="password" class="form-control"  v-model="pass" placeholder="密码" name="pass">
                                </div>
                                <span id="login-dialog" class="pull-left"> </span>
                                <button type="button" class="btn btn-primary form-control mt-3 mb-5"  @click="userLogin" >立即登录</button>
                                <div class="zhuce-mima" style="margin-top: 20px;">
                                    <a style="margin-right: 10px;"  href="https://account.bimfilm.cn/bimfilm/registeruser?type1=1" target="_blank" >注册</a>
                                    <a  href="https://account.bimfilm.cn/bimfilm/forgetpwd" target="_blank" >找回密码</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!-- </div> -->
</template>
<script>
    import { requestLogin,requestStorage } from "../api/request";
    export default {
    name:"",
    data(){
        return {
            user: "", //登录时填写的用户（手机号）
            pass: "", //密码
        };
    },
    props:{
        title:String
    },
    methods:{
         closeDialog(){
             this.$emit("closeDialog",false)
         },
        //登陆
        userLogin() {

             let data_this=this;
            if (this.user === "" || this.pass === "") {
                this.$message({
                    message: "账号密码不能为空",
                    type: "warning",
                });
                return;
            }
            let paramData = {
                $router: this.$router,
            };
            let obj=Object();
            obj.account=this.user;
            obj.password=this.$md5(this.pass);
            requestLogin(obj).then(res => {
                if (res.code == 0) {
                    // localStorage.setItem("token","f225fe8c1a4fc360153bdc390c383425");
                    localStorage.setItem("token",res.ret.token);
                    localStorage.setItem("session", JSON.stringify(res.ret));
                    data_this.$emit("closeDialog",false);
                    requestStorage().then(res => {
                        localStorage.setItem("storage",JSON.stringify(res.ret));

						history.go(0);
                        data_this.$parent.isLogin1();
                    })
                    data_this.$message({
                        message: '恭喜你，登录成功',
                        type: 'success'
                    });
                }
            })

        },
    }
}
</script>
<style  scoped>

.login-dialog{
    position: relative;
    height:100%;
    width:100%;
}
.draw-Box{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    background: rgba(0,0,0,0.5);
    z-index: 2000;
}
.model-dialog{
    z-index:2000;
    margin-top: 0px;
    position: absolute;
    top:50%;
    left:50%;
    margin-top:-250px;
    margin-left:-300px;
}
.modal-content {
    width: 600px;
    height: 511px;
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}
.modal-header {
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.align-content-center {
    align-content: center!important;
}
.justify-content-center {
    justify-content: center!important;
}
.row {
	padding-left: 120px;
	padding-right:120px;
}
.text-center {
    text-align: center!important;
}
.mt-5, .my-5 {
    margin-top: 3rem!important;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
a:link, a:visited, a:hover {
    text-decoration: none;
}
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.zhuce-mima{
    display: flex;
    justify-content: flex-end;
    color: #9D9D9D;
}
.zhuce-mima a:hover{
	color: #007AFF;
	 cursor: pointer;
}

</style>

import Vue from 'vue'
import VueRouter from 'vue-router'

import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
const routes = [{
    path: '/',
    name: 'Home',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Home.vue'),
        meta: {
            title: "北京睿视新界科技有限公司",
            show:true
        }
    },
    {
        path: '/SignIn',
        name: 'SignIn',
        component: () =>
            import ('../views/SignIn.vue'),
        meta: {
            title: "北京睿视新界科技有限公司",
            show:true
        }
    },
    {
        path: '/about',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },
    {
        path: '/vipprivilge',
        name: 'VipPrivilge',
        component: () =>
            import ('../views/VipPrivilge.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },
    {
        path: '/MyWallet',
        name: 'MyWallet',
        component: () =>
            import ('../views/MyWallet.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },
    {
        path: '/MyInform',
        name: 'MyInform',
        component: () =>
            import ('../views/MyInform.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },
    {
        path: '/accountSecurity',
        name: 'AccountSecurity',
        component: () =>
            import ('../views/AccountSecurity.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },
    {
        path: '/memoryspace',
        name: 'MemorySpace',
        component: () =>
            import ('../views/MemorySpace.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },
    {
        path: '/MyShow',
        name: 'MyShow',

        component: () =>
            // import ('../views/MyShow/MyShowProject.vue')
            import ('../views/MyShow/MyShow.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },
    {
        path: '/MyShowCollection',
        name: 'MyShowCollection',
        component: () =>
            import ('../views/MyShow/MyShowRightShare.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },
	{
	    path: '/QRCodeModeResources',
	    name: 'QRCodeModeResources',
	    component: () =>
	        import ('../views/QRCodeModeResources.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
	},
    {
        path: '/ModeBase',
        name: 'ModeBase',
        component: () =>
            import ('../views/ModeBase.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },{
        path: '/Searchlist',
        name: 'Searchlist',
        component: () =>
            import ('../views/Searchlist.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },{
        path: '/ModeResources',
        name: 'ModeResources',
        component: () =>
            import ('../views/ModeResources.vue'),
        meta: {
            title: "北京睿视新界科技有限公司",
            show:true
        }
    },{
        path: '/ModeUpload',
        name: 'ModeUpload',
        component: () =>
            import ('../views/ModeUpload.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },
    {
        path: '/Myshoppingcart',
        name: 'Myshoppingcart',
        component: () =>
            import ('../views/Myshoppingcart.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },
    {
        path: '/MyPay',
        name: 'MyPay',
        component: () =>
            import ('../views/MyPay.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },
    {
        path: '/MySpace',
        name: 'MySpace',
        component: () =>
            import ('../views/MySpace.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },{
        path: '/PaidServices',
        name: 'PaidServices',
        component: () =>
            import ('../views/PaidServices.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },{
        path: '/MyTixian',
        name: 'MyTixian',
        component: () =>
            import ('../views/MyTixian.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },{
        path: '/ModeScriptPreview',
        name: 'ModeScriptPreview',
        component: () =>
            import ('../views/ModeScriptPreview.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },{
        path: '/ResourceAllocationDirectory',
        name: 'ResourceAllocationDirectory',
        component: () =>
            import ('../views/MyShow/ResourceAllocationDirectory.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },{
        path: '/MyShowgomaijilu',
        name: 'MyShowgomaijilu',
        component: () =>
            import ('../views/MyShow/MyShowgomaijilu.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },{
        path: '/PanoramaViewer',
        name: 'PanoramaViewer',
        component: () =>
            import ('../views/PanoramaViewer.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
    },
	{
	    path: '/BIMregister',
	    name: 'BIMregister',
	    component: () =>
	        import ('../views/BIMregister.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
	},{
	    path: '/OfficialActivities',
	    name: 'OfficialActivities',
	    component: () =>
	        import ('../views/OfficialActivities.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
	},{
	    path: '/OfficialActivities_details',
	    name: 'OfficialActivities_details',
	    component: () =>
	        import ('../views/OfficialActivities_details.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
	},
	{
		path: '',
		component: () =>
		    import ('../views/Home.vue'),
			meta: {
			    title: "北京睿视新界科技有限公司",
			    show:true
			}
	}
    ,


]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
